.App {
  font-family: inter-variable, sans-serif; 
  max-width: 100vw;
  overflow-x: hidden;
}

.button {
  min-width: 175px;
  min-height: 50px;
  background-color: rgba(black, 0.8);
  border: solid white 1px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 10px rgba(black, 0.5));
  color: white;

  cursor: pointer;

  transition: 0.25s;
}
.button:hover {
  transform: scale(1.1);
}
.video-container {
  max-width: 1000px;
  width: 100%;    

  .video-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
  
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
  }
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: url(../img/kiseki.jpg);
  background-position: center;
  background-size: cover;
}

.hero-title {
  background-image: url('../img/gears.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  margin: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 95vw;
  width: 800px;

  color: white;
  text-align: center;

  padding: 50px;

  filter: drop-shadow(0px 0px 4px rgba(black, 0.4));

  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2em;
  }
  hr {
    height: 2px;
    background-color: white;
    width: 100%;
  }
}

.character-section {
  margin-top: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: calc(100vw * 1/6);
  }
}

.news-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: #333;

  color: white;

  
  border: solid white 2px;

  a {
    width: 100%; 
    text-decoration: none;
    color: white;
  }
}

.news-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  height: auto;

  height: 100%;

  a {
    width: 100%; 
    text-decoration: none;
    color: white;
  }

  .news-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 10px;

    .news-image {
      width: 100%;
      height: auto;

      img {
        width: 100%;
      }
    }
  }

  .news-title {
    text-align: right;

    width: 100%;
    
    padding: 10px;

    h2 {
      
      font-weight: 100;
      font-size: 2em;
    }
    h1 {
      font-size: 4em;
    }
  }

  .news-text {
    display: flex;
    justify-content: flex-start;
  }

  .news-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;
  }
}

.news-right {
  max-width: 90vw;
  width: 700px;
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;

  
  border-left: solid white 2px;

  .news-text {
    display: flex;
    justify-content: flex-start;
  }

  .news-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px;
  }
}

.category {
  text-transform: uppercase;
  font-size: 1em;
}


.article-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 10px;

  min-width: 200px;
}

.news-card {
  display: flex;
  justify-content: flex-start;
  align-items: center; 

  width: 100%;

  padding: 10px;

  transition: 0.25s;

  .date {
    font-weight: 100;
    font-family: inter-variable;
  }
}

.news-card:hover {
  background-color: rgba(white, 0.2);
}

.news-image {
  width: 150px;
  height: calc(150px * 9/16);

  display: flex;
  justify-content: center;

  img {
    width: 150px;
  }
}

.store-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  background-image: url(../img/map.jpg);
  padding: 2vw;

  
.swiper-pagination-bullet {
  background-color: black;
  width: 10px;
  height: 10px;
}

.swiper-wrapper {
  background-color: white;
}

.swiper-pagination {
  position: relative; /* Moves the pagination below the slide */
  text-align: center;
}

.swiper-button-next {
  color: black;
  transition: 0.25s;
}
.swiper-button-prev {
  color: black;
  transition: 0.25s;
}

.swiper-button-next:hover {
  transform: scale(1.1);
}
.swiper-button-prev:hover {
  transform: scale(1.1);
}


  .swiper {
    background-color: white;
    border-radius: 10px;

    transition: 0.25s;
  }
  .swiper:hover {
    filter: drop-shadow(0px 0px 10px rgba(white, 0.6));
  }

  a {
    padding: 10px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;

    img {
      width: 100%;
      margin: 10px;
    }

    .button {
      margin-bottom: 10px;
      font-family: 'inter-variable';
      font-size: 1em;
      padding-top: 4px;
    }
  }
}

.store-title {
  font-size: 3em;
  color: white;
  padding-top: 50px;
}

.store-carousel {
  max-width: 90vw;
  margin-bottom: 50px;
}

.store-left {
  max-width: 800px;
  .store-item {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 60vh;
  }
}

.store-right {
  max-width: 400px;
}

.scroll {
  border-bottom: 2px solid white;
  .swiper-wrapper {
    transition-timing-function: linear; /* Change this to whatever timing function you want */
  }
  .swiper-slide {
    width: 320px;
    height: 250px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 350px;
    }
  }
}

.scroll-ss {
  border-top: 2px solid white; 
  border-bottom: 2px solid white;
  .swiper-slide {
    width: 320px;
    height: 250px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      width: auto;
    }
  }
}


.modal-container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(black, 0.8);

  z-index: 5;
  

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  pointer-events: none;
}

.modal-close {
  position: absolute;

  top: 0;
  right: 0;
  margin: 40px 50px;

  background-color: white;
  border: solid white 2px;
  border: 5px;

  font-size: 2em;
  transform: scaleX(1.5);

  cursor: pointer;
}

.modal-visible {  
  visibility: visible;
  pointer-events: all;
}

.portal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  width: 600px;
  min-width: 50vw;

  background-position: center;
  background-size: cover;

  .trailer-button {
    border: 0px;
  }

  button {
    min-width: 180px;
    height: 50px;
    padding: 10px;
  
    font-size: 1.15em;
    font-weight: 500;

    margin-bottom: 15px;

    cursor: pointer;

    transition: 0.25s;
  }
  button:hover {
    transform: scale(1.1);
  }
}

.main {
  .portal-container {
    width: 100%;
    padding: 40px;
  }
}

.other {  
  display: flex;
  width: 100vw;
  overflow: hidden; /* Prevents scrollbars */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .portal-container {
    height: 900px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 50vw;
    width: 1000px;

    .portal-logo {
      padding-bottom: 25px;
    }
  }
}

.portal-logo {

  display: flex;
  justify-content: center;
  align-items: center;

  height: 400px;
  img {
    max-width: 90vw;
    width: 650px;
    height: auto;
  }
}

.double-logo {
  height: 400px;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
  .portal-logo {
    max-width: 50%;
    min-width: 350px
  }
  .three-logo {
    width: 95%;
  }
  .four-logo {
    width: 95%;
  }
}

.portal-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  filter: drop-shadow(0px 0px 4px rgba(black, 0.5));

  .trailer-button {
    height: auto;
    background-color: transparent !important;
    background-image: none !important;

    padding: 0px;
    max-width: 450px;
    width: 90vw;

    cursor: pointer;

    img {
      width: 100%;
    }

    transition: 0.25s
  }
  .trailer-button:hover {
    filter: brightness(1.25);
  }
}

.db-ii-bg {
  background-image: url('../img/db-ii-bg.webp')
}

.db-bg {
  background-image: url('../img/db-bg.png');
  background-size: auto 120%;
}
.double-bg {
  background-image: url('../img/34-bg.jpg')
}
.nayuta-bg {
  background-image: url('../img/nayuta.jpg')
}
.reverie-bg {
  background-image: url('../img/reverie.webp')
}
.zero-bg {
  background-image: url('../img/zero-bg.webp')
}
.azure-bg {
  background-image: url('../img/azure-bg.webp')
}


@media screen and (max-width: 1350px) {
  .news-right {
    max-width: 100vw;
    border: 0px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .news-text {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
    }

    .news-details {
      display: flex;
      flex-direction: row;

      padding: 5px;

      .category {
        width: 100px;
        text-align: left;
      }
    }

    .article-title {
      padding: 5px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .other {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 100vw;
    
    .portal-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      max-width: 100vw;
      width: 100vw;
    }
  }
  .double-bg {
    height: auto !important;
    padding: 25px;
  }
  .double-logo {
    height: auto;
    .portal-logo {
      max-width: none;
      height: auto;
    }
  }  
}
